<template>
  <div class="overlay pt-0 pt-md-5">
    <div class="container">
      <router-link to="/" class="btn btn-secondary btn-sm">
        <i class="icofont-arrow-left"></i> {{ $t('word.back') }}
      </router-link>
      <div class="container section-title aos-init aos-animate">
        <h2 class="page-header mb-0 d-none d-md-block">
          {{ $t('phrase.our_training_programs') }}
        </h2>
        <h3 class="page-header mb-0 d-block d-md-none">
          {{ $t('phrase.our_training_programs') }}
        </h3>
        <p class="text-muted text-lg-center">
          {{ $t('text.training_programs_subtitle') }}
        </p>
      </div>
      <hr/>
      <div class="row">
        <div
          v-for="(program, index) in trainingPrograms"
          :key="index"
          class="col-md-6 mb-3"
        >
          <div class="mb-3">
            <transition name="from-right">
              <v-training-program-card
                v-if="isOdd(index) && showOdd || isEven(index) && showEven"
                :slug="program.slug"
                :title="program.title"
                :subtitle="program.subtitle"
                :description="program.description"
                :image="program.image"
                :duration="program.duration"
                :audience="program.audience"
              />
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrainingProgramCard from "@/components/TrainingProgramCard.vue";
import i18n from "@/i18n";
import trainingPrograms from "@/data/trainingPrograms";

export default {
  name: "TrainingList",
  components: {
    'v-training-program-card': TrainingProgramCard,
  },
  methods: {
    isOdd(index) {
      return index % 2 === 0;
    },
    isEven(index) {
      return index % 2 === 1;
    },
  },
  data() {
    return {
      trainingPrograms: trainingPrograms[i18n.global.locale],
      showOdd: false,
      showEven: false,
    }
  },
  created() {
    setTimeout(() => {
      this.showOdd = true;
    }, 200);

    setTimeout(() => {
      this.showEven = true;
    }, 800);


  }
}
</script>

<style scoped>
h2.page-header {
  font-size: 50px !important;
}

.overlay {
  min-height: 100vh;
  background: rgba(255, 255, 255, 0.7);
}
</style>
