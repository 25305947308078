<template>
  <header
    id="header"
    class="fixed-top d-flex align-items-center"
    :class="{'header-scrolled': scrollPos > 100}"
  >
    <div class="container d-flex align-items-center">
      <div class="logo mr-auto">
        <h1 class="text-light">
          <a href="#top" v-smooth-scroll>
            <span class="text-ci">CI</span>
            <span>Solutions</span>
          </a>
        </h1>
      </div>

      <transition name="fade">
        <nav class="nav-menu d-none d-lg-block">
          <ul>
            <li>
              <a
                href="#what"
                v-smooth-scroll
              >
                {{ $t('sentence.what_we_do') }}
              </a>
            </li>
            <li>
              <a
                href="#use-cases"
                v-smooth-scroll
              >
                {{ $t('word.use_cases') }}
              </a>
            </li>
            <li v-if="false">
              <a
                href="#references"
                v-smooth-scroll
              >
                {{ $t('word.references') }}
              </a>
            </li>
            <li>
              <a
                href="#who-we-are"
                v-smooth-scroll
              >
                {{ $t('sentence.who_we_are') }}
              </a>
            </li>
            <li>
              <a
                href="#contact"
                v-smooth-scroll
              >
                {{ $t('word.contact') }}
              </a>
            </li>
            <li>
              <v-language-select />
            </li>
          </ul>
        </nav>
      </transition>
      <nav class="nav-menu d-lg-none">
        <ul>
          <li>
            <v-language-select />
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import {defineComponent} from 'vue';
import LanguageSelect from "@/components/LanguageSelect.vue";

export default defineComponent({
  components: {
    'v-language-select': LanguageSelect,
  },
  props: {
    scrollPos: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
});
</script>

<style scoped>
.text-ci {
  color: #ffffff;
}

.header-scrolled .text-ci {
  color: #AC53B4;
}

@media screen and (max-width: 768px) or (min-width: 1596px) {
  .text-ci {
    color: #AC53B4;
  }
}

@media screen and (min-width: 1596px) {
  .text-ci {
    color: #AC53B4;
  }
}
</style>
