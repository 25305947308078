<template>
  <div class="card card-body mb-3">
    <h1 class="text-center">
      <i class="text-purple" :class="item.iconClass"></i>
    </h1>
    <h2 class="text-center mb-2">
      {{ $t(item.title) }}
    </h2>
    <p class="font-weight-light line-higher text-justify">
      {{ $t(item.text) }}
    </p>

    <div class="d-flex justify-content-end">
      <a
        v-if="item.link"
        :href="item.link.path"
        class="text-purple"
      >
        <i :class="item.link.icon"></i>
        {{ $t(item.link.text) }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemWhatWeDo',
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style scoped>
.card {
  background: #e6cbe8;
  box-shadow: rgba(165, 172, 180, 0.6) 0 8px 24px;
  border: 0;
  transition: transform .2s; /* Animation */
}

.card:hover {
  transform: scale(1.03);
}
</style>
