<template>
  <carousel
    :items-to-show="1"
    :mouse-drag="false"
    :touch-drag="false"
    ref="carouselUseCases"
  >
    <slide
      v-for="(useCaseSet, index) in useCaseSets"
      :key="index"
    >
      <div class="d-flex flex-column">
        <div
          class="card card-body card-use-case mb-3"
          v-for="(useCase, index) in useCaseSet"
          :key="index"
        >
          <h4 class="text-purple-light">{{ $t(useCase.title) }}</h4>
          <p class="p-0 m-0 text-justify line-high font-weight-light">
            {{ $t(useCase.text) }}
          </p>
        </div>
      </div>
    </slide>

    <template #addons>
      <button
        @click="toggle()"
        class="btn btn-use-cases text-white bg-purple"
        :class="{'toggle-right': currentSlide === 0, 'toggle-left': currentSlide === 1}"
      >
        <i class="ri-arrow-right-s-fill"></i>
      </button>
    </template>
  </carousel>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';

export default {
  name: 'CarouselUseCases',
  components: {
    Carousel,
    Slide,
  },
  methods: {
    toggle() {
      if (this.currentSlide === 0) {
        this.$refs.carouselUseCases.next();
      } else {
        this.$refs.carouselUseCases.prev();
      }
      this.currentSlide = this.$refs.carouselUseCases.data.currentSlide.value;
    },
  },
  data() {
    return {
      currentSlide: 0,
      useCaseSets: [
        [
          {
            title: 'sentence.secret_management_implementation',
            text: 'text.secret_management_implementation'
          },
          {
            title: 'sentence.automate_pipelines',
            text: 'text.automate_pipelines'
          },
        ],
        [
          {
            title: 'sentence.interim_software_engineer',
            text: 'text.interim_software_engineer'
          },
          {
            title: 'sentence.improvement_processes',
            text: 'text.improvement_processes'
          }
        ]
      ],
    };
  }
};
</script>

<style scoped>
.card-use-case {
  background: rgba(0, 0, 0, 0.2);
  color: white;
  text-align: left;
}

.btn-use-cases {
  transition: all 0.75s 0.25s;
}

.toggle-left {
  transform: rotate(180deg);
}

.toggle-right {
  transform: rotate(0);
}
</style>
