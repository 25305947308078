import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/Home.vue';
import TrainingList from "@/views/TrainingList.vue";
import TrainingDetail from "@/views/TrainingDetail.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/training',
    name: 'TrainingList',
    component: TrainingList,
  },
  {
    path: '/training/:slug',
    name: 'TrainingDetail',
    component: TrainingDetail,
    meta: {
      hideBackground: true,
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
