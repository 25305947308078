<template>
  <div
    @click="$router.push({name: 'TrainingDetail', params: {slug}})"
    class="card clickable"
  >
    <div
      class="image-training-program"
      :style="{'background-image': `url(${require(`../assets/img/training/${image}`)})`}"
    >
    </div>
    <div class="p-3 overlay">
      <h4>
        {{ title }}
        <br/>
        <small>
          <em>{{ subtitle }}</em>
        </small>
      </h4>
      <hr/>
      <div class="m-0 description-container">
        <em>{{ description }}</em>
      </div>
    </div>
    <table class="table mb-0">
      <tr>
        <td>
          <b>{{ $t('phrase.how_long') }}</b>
        </td>
        <td>
          {{ duration }}
        </td>
      </tr>
      <tr>
        <td class="w-25">
          <b>{{ $t('phrase.for_who') }}</b>
        </td>
        <td>
          <div
            class="badge badge-light mr-2"
            v-for="(audience_single) in audience"
            :key="audience_single"
          >
            {{ audience_single }}
          </div>
        </td>
      </tr>
    </table>
    <div class="card-footer d-flex justify-content-end" v-if="false">
      <button class="btn bg-purple text-white btn-sm">
        {{ $t('phrase.more_info') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrainingProgramCard",
  props: {
    slug: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    duration: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    audience: {
      type: Array,
      required: true,
    },
  },
  computed: {
    truncatedDescription() {
      let words = this.description.split(" ");
      return words.slice(0, 30).join(" ") + "...";
    },
  }
}
</script>

<style scoped>
.image-training-program {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 200px;
}

.overlay {
  min-height: 200px;
  background: rgba(255, 255, 255, 1);
}

.description-container {
  height: 120px;
  overflow: auto;
}
</style>
