<template>
  <div>
    <v-section-header/>
    <v-technologies/>
    <v-section-what-we-do/>
    <v-technologies/>
    <v-section-use-cases/>
    <v-section-references
      v-if="false"
    />
    <v-section-who-we-are />
    <v-section-contact/>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import SectionContact from '@/components/SectionContact.vue';
import SectionHeader from '@/components/SectionHeader.vue';
import SectionWhatWeDo from '@/components/SectionWhatWeDo.vue';
import SectionUseCases from '@/components/SectionUseCases.vue';
import Technologies from '@/components/Technologies.vue';
import SectionReferences from "@/components/SectionReferences.vue";
import SectionWhoWeAre from "@/components/SectionWhoWeAre.vue";

export default defineComponent({
  components: {
    'v-section-contact': SectionContact,
    'v-section-header': SectionHeader,
    'v-section-references': SectionReferences,
    'v-section-use-cases': SectionUseCases,
    'v-section-what-we-do': SectionWhatWeDo,
    'v-section-who-we-are': SectionWhoWeAre,
    'v-technologies': Technologies,
  },
});
</script>
