<template>
  <section id="clients" class="clients">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-2 col-md-4 col-6"
          v-for="(technology, index) in technologies"
          :key="index"
        >
          <img
            :src="require(`@/assets/img/technologies/${technology.image}`)"
            class="img-fluid aos-init aos-animate"
            :alt="technology.name"
            data-aos="zoom-in"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const TECHNOLOGIES = [
  {
    image: 'python.png',
    name: 'Python',
  },
  {
    image: 'django.png',
    name: 'Django',
  },
  {
    image: 'fastapi.png',
    name: 'FastAPI'
  },
  {
    image: 'flask.png',
    name: 'Flask'
  },
  {
    image: 'pytest.png',
    name: 'Pytest'
  },
  {
    image: 'javascript.png',
    name: 'JavaScript',
  },
  {
    image: 'vue.png',
    name: 'Vue',
  },
  {
    image: 'ansible.png',
    name: 'Ansible',
  },
  {
    image: 'docker.png',
    name: 'Docker',
  },
  {
    image: 'kubernetes.png',
    name: 'Kubernetes',
  },
  {
    image: 'postgres.png',
    name: 'PostgreSQL',
  },
  {
    image: 'pandas.png',
    name: 'Pandas'
  },
  {
    image: 'mongo.png',
    name: 'MongoDB',
  },
  {
    image: 'bash.png',
    name: 'Bash',
  },
  {
    image: 'aws.png',
    name: 'Amazon Web Services',
  },
  {
    image: 'gcp.png',
    name: 'Google Cloud Platform',
  },
]

export default {
  name: 'Technologies',
  data() {
    return {
      TECHNOLOGIES,
      technologies: []
    };
  },
  methods: {
    getRandomInteger(max) {
      return Math.floor(Math.random() * max);
    },
    setTechnologies() {
      this.technologies = [];
      let technologies = JSON.parse(JSON.stringify(TECHNOLOGIES));
      for (let i = 0; i < 6; i++) {
        this.technologies.push(
          technologies.splice(this.getRandomInteger(technologies.length), 1)[0]
        );
      }
      setTimeout(this.setTechnologies,10000);
    }
  },
  created() {
    this.setTechnologies();
  }
};
</script>
