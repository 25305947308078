<template>
  <section
    id="contact"
    class="contact min-vh-100 d-flex align-items-center bg-dark text-white"
  >
    <div class="container">
      <h1 class="text-center font-weight-light mb-5 text-white">
        {{ $t('word.contact') }}
      </h1>
      <div class="row">

        <div class="col-lg-4 col-md-6">
          <div class="contact-about">
            <h3 class="text-white">{{ $t('sentence.who_we_are') }}.</h3>
            <p class="text-justify text-white" v-html="$t('text.who_we_are')"></p>
            <p class="text-justify text-white">
              {{ $t('text.more_information') }}
            </p>

            <div class="social-links">
              <a href="https://www.linkedin.com/company/3519226" target="_blank" class="linkedin">
                <i class="icofont-linkedin"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 mt-4 mt-md-0 ">
          <div class="info">
            <div class="d-flex flex-column align-items-center">
              <i class="ri-map-pin-line"></i>
              <p class="p-0 text-center text-white">
                {{ address }}
                <br>
                {{ postalCode }}, {{ city }}
              </p>
            </div>

            <div class="d-flex flex-column align-items-center text-white">
              <i class="ri-mail-send-line"></i>
              <p class="p-0 text-center">{{ email }}</p>
            </div>

            <div class="d-flex flex-column align-items-center text-white">
              <i class="ri-phone-line"></i>
              <p class="p-0 text-center">{{ phone }}</p>
            </div>

            <div class="d-flex flex-column align-items-center text-white">
              <i class="ri-file-list-line"></i>
              <p class="p-0 text-center">Kvk. {{ kvk }}</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-12 d-flex justify-content-center">
          <img
            src="../assets/img/logo-v3.svg"
            class="img-fluid"
            data-aos="fade"
            data-aos-duration="1000"
            data-aos-once="false"
            alt="logo"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Contact',
  data() {
    return {
      address: 'Maastrichtkade 30',
      postalCode: '3826 KC',
      city: 'Amersfoort',
      email: 'info@cisolutions.nl',
      phone: '+31 6 128 322 10',
      kvk: '89 270 614',
    };
  }
};
</script>
