<template>
  <section
    id="what"
    class="min-vh-100 section-what-we-do d-flex flex-column justify-content-center"
  >
    <h1 class="text-center font-weight-light mb-5">
      {{ $t('sentence.what_we_do') }}
    </h1>

    <div class="container">
      <div class="row d-none d-md-flex">
        <div
          class="col-md-6 d-flex justify-content-center"
          v-for="(item, index) in items"
          :key="index"
          data-aos="fade"
          :data-aos-delay="500"
          :data-aos-duration="500"
          data-aos-once="false"
        >
          <v-item-what-we-do
            :item="item"
          />
        </div>
      </div>
      <div class="row d-flex d-md-none">
        <div
          class="col-lg-4 d-flex justify-content-center"
          v-for="(item, index) in items"
          :key="index"
          data-aos="fade"
          :data-aos-duration="1000"
          data-aos-once="false"
        >
          <v-item-what-we-do
            :item="item"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ItemWhatWeDo from "@/components/ItemWhatWeDo.vue";

export default {
  name: 'WhatWeDo',
  components: {
    'v-item-what-we-do': ItemWhatWeDo,
  },
  data() {
    return {
      items: [
        {
          title: 'word.dev_sec_ops',
          text: 'text.dev_sec_ops',
          iconClass: 'icofont-server',
          onLoadAnimation: 'flip-down',
          onLoadAnimationDelay: 100,
        },
        {
          title: 'word.training',
          text: 'text.training',
          iconClass: 'icofont-users',
          onLoadAnimation: 'flip-down',
          onLoadAnimationDelay: 100,
          link: {
            path: '/training',
            text: 'phrase.read_more',
            icon: 'icofont-eye-open'
          }
        },
        {
          title: 'word.security_consultancy',
          text: 'text.security_consultancy',
          iconClass: 'icofont-ssl-security',
          onLoadAnimation: 'flip-right',
          onLoadAnimationDelay: 500,
        },
        {
          title: 'word.software_consultancy',
          text: 'text.software_consultancy',
          iconClass: 'icofont-code',
          onLoadAnimation: 'flip-left',
          onLoadAnimationDelay: 900,
        }
      ]
    };
  }
};
</script>

<style scoped>
section.section-what-we-do {
  background: linear-gradient(-45deg, #c486ca, #e6cbe8, #eedcf0, #f6edf7);
  background-size: 200% 200%;
  animation: gradient 10s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
