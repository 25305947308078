<template>
  <section
    class="vh-100 d-flex align-items-center section-head"
  >
    <div class="container">
      <div class="row">
        <div
          class="col-md-6 d-flex justify-content-center align-items-center"
        >
          <img
            src="../assets/img/logo-v3.svg"
            class="d-block d-md-none img-fluid mb-4"
            data-aos="fade-down"
            data-aos-duration="900"
            data-aos-delay="500"
            data-aos-once="false"
            alt=""
          >
        </div>
        <div
          class="col-md-6"
        >
          <h1 class="mb-2 mb-lg-4 text-center text-md-right">
            <span
              class="text-purple"
              data-aos="fade-up"
              data-aos-duration="1000"
            >CI</span><span
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="250"
            >Solutions</span>
          </h1>
          <h2
            class="sub-title text-muted text-center text-md-right"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="750"
          >
            <span v-html="$t('sentence.slogan')"></span>
          </h2>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      duration: 2000,
      offset: -50,
      container: '#about',
      updateHistory: false
    };
  },
});
</script>


<style scoped>
.section-head {
  background: rgba(255, 255, 255, 0.3);
}


h2.sub-title {
  font-size: 30px !important;
}

h1 span.text-ci {
  font-size: 30px;
}
@media (max-width: 768px) {
  h2.sub-title {
    font-size: 20px !important;
  }

  h1 span.text-ci {
    font-size: 20px;
  }
}

</style>
