export default {
    nl: [
        {
            "slug": "secret-management-voor-devsecops",
            "title": "Secrets Management voor DevOps teams",
            "subtitle": "Een praktische training voor effectief Secrets Management binnen DevOps teams",
            "duration": "1 dag",
            "audience": ["Developers", "DevOps Engineers", "Security Engineers", "Security Architects"],
            "description": "Deze training is een interactief programma dat speciaal is ontworpen om ontwikkelaars uit te rusten met essentiële vaardigheden en kennis voor effectief beheer van gevoelige informatie in software-ontwikkeling omgevingen. Deelnemers verkennen de relevantie, implementatiestrategieën en praktische tools en technologieën die nodig zijn voor een robuust beheer van gevoelige informatie. Door theoretische inzichten te combineren met praktijkvoorbeelden uit de echte wereld, beoogt deze training de beveiliging praktijken te verbeteren, werkprocessen te optimaliseren en een dieper begrip te stimuleren van het beschermen van vertrouwelijke gegevens in DevOps-contexten.",
            outline: [
                {
                    title: "Introductie in Secrets Management",
                    description: "This section introduces the concept of secrets management, explaining its role in protecting sensitive data like passwords, tokens, and keys in DevOps teams. It emphasizes the importance of managing these secrets to safeguard against data breaches and security lapses. Participants will learn the basic principles and the necessity of incorporating secrets management into their DevOps practices."
                },
                {
                    title: "Waarom moet jouw bedrijf aan Secrets Management doen?",
                    description: "Here, we dive into the reasons why secrets management is crucial in modern software development. The focus will be on the risks associated with poor secrets management practices, including potential security breaches and compliance violations. This section aims to highlight the consequences of neglecting secrets management and the benefits of implementing robust secrets management practices."
                },
                {
                    title: "Hoe implementeer je Secrets Management succesvol?",
                    description: "This critical section outlines the strategies and best practices for successfully implementing secrets management. It covers the selection of appropriate tools, integration into existing workflows, and the establishment of policies for secret creation, storage, access, and rotation. The goal is to provide a roadmap for setting up an effective and secure secrets management system."
                },
                {
                    title: "Wat is het voordeel van Secrets Management voor de developer?",
                    description: "In this part, we explore the direct benefits of secrets management for developers, such as streamlined workflow, enhanced security, and reduced risk of human error. It emphasizes how secrets management can simplify development processes, improve security posture, and ultimately save time and resources. The aim is to illustrate how secrets management directly contributes to a developer's efficiency and the overall security of the project."
                },
                {
                    title: "Infrastructuur, tools en technologie",
                    description: "This section provides an overview of the infrastructure requirements for implementing secrets management and reviews various tools and technologies available, such as HashiCorp Vault, AWS Secrets Manager, and Cyberark Conjur. It offers a comparison of features, usability, and scalability to help participants choose the right tools for their needs. The focus is on equipping participants with the knowledge to make informed decisions about the tools and technologies that best fit their organizational context."
                },
                {
                    title: "Case studies en real-world voorbeelden (Live demos)",
                    description: "This section presents case studies and real-world examples of secrets management implementations. These stories from various organizations demonstrate the practical application of concepts taught, highlighting both successes and challenges. Participants will gain insights into how different strategies work in real-world scenarios, providing valuable lessons that can be applied in their own DevOps environments."
                },
                {
                    title: "Hands-On workshop waarin de developer een secret manager implementeert",
                    description: "In this hands-on workshop, participants will gain practical experience by integrating a secrets management tool into their own project. Guided by instructors, they will learn the step-by-step process of configuring and connecting a secret manager, such as HashiCorp Vault or Cyberark Conjur, to their existing development environment. This interactive session emphasizes real-world application, offering participants the opportunity to directly apply their learning and understand the nuances of effectively managing secrets within their projects."
                },
            ],
            "image": "secret-management-for-devops.png"
        },
        {
            "slug": "devsecops-voor-de-business",
            "title": "DevOps Security voor de Business",
            "subtitle": "Cruciaal voor de bedrijfsvoering: de rol van DevOps Security kennis voor de business",
            "duration": "1 dagdeel",
            "audience": ["Product owners", "Business consultants", "Scrum Masters", "Project Managers"],
            "description": "DevOps Security voor de Business\" is een training gericht op het integreren van beveiliging in de DevOps-processen van een bedrijf. Deze training biedt inzicht in hoe beveiliging geïmplementeerd kan worden in de DevOps-lifecycle om zo de veiligheid van software te verbeteren. Naast de technische aspecten richt deze training zich ook op de zakelijke kant van DevOps Security, met de nadruk op het begrijpen van de voordelen, impact en implementatie van beveiliging binnen de bredere bedrijfsstrategie. Het doel is om deelnemers in staat te stellen een cultuur van 'security' te bevorderen en effectief samen te werken tussen ontwikkeling, beveiliging en operationele teams. De training DevOps Security voor de Business is bedoeld voor Product Owners, Scrum Masters en andere functies gerelateerd aan de bedrijfsvoering. Wij zijn ervan overtuigd dat de zakelijke kant kennis van DevOps Security nodig heeft om risico's te minimaliseren, efficiëntie te verhogen en het vertrouwen binnen het team te versterken.",
            outline: [
                {
                    title: "Introductie in DevOps",
                },
                {
                    title: "Waarom de business kennis nodig heeft van DevOps Security",
                },
                {
                    title: "Security concepten en terminologie",
                },
                {
                    title: "Security uitdagingen in DevOps",
                },
                {
                    title: "Compliance en Wetgeving",
                },
                {
                    title: "Security in Development",
                },
                {
                    title: "Security in Operations",
                },
                {
                    title: "Security gebieden binnen DevOps",
                },
            ],
            "image": "devsecops-for-the-business.png"
        },
    ],
    en: [
        {
            "slug": "secret-management-for-devsecops",
            "title": "Secrets Management for DevOps teams",
            "subtitle": "A complete and practical guide to effective Secrets Management",
            "duration": "1 day",
            "audience": ["Developers", "DevOps Engineers", "Security Engineers", "Security Architects"],
            "description": "This training is an interactive training program designed to equip developers with the essential skills and knowledge for effective secrets management in software development environments. Participants will explore the importance, implementation strategies, and practical tools and technologies necessary for robust secrets management. Through a mix of theoretical insights and real-world case studies, this training aims to enhance security practices, streamline workflows, and encourage a deeper understanding of safeguarding sensitive data in DevOps contexts.",
            outline: [
                {
                    title: "Introduction into Secrets Management",
                    description: "This section introduces the concept of secrets management, explaining its role in protecting sensitive data like passwords, tokens, and keys in DevOps teams. It emphasizes the importance of managing these secrets to safeguard against data breaches and security lapses. Participants will learn the basic principles and the necessity of incorporating secrets management into their DevOps practices."
                },
                {
                    title: "Why Secrets Management?",
                    description: "Here, we dive into the reasons why secrets management is crucial in modern software development. The focus will be on the risks associated with poor secrets management practices, including potential security breaches and compliance violations. This section aims to highlight the consequences of neglecting secrets management and the benefits of implementing robust secrets management practices."
                },
                {
                    title: "How to Implement Secrets Management Successfully?",
                    description: "This critical section outlines the strategies and best practices for successfully implementing secrets management. It covers the selection of appropriate tools, integration into existing workflows, and the establishment of policies for secret creation, storage, access, and rotation. The goal is to provide a roadmap for setting up an effective and secure secrets management system."
                },
                {
                    title: "What's in it for the Developer?",
                    description: "In this part, we explore the direct benefits of secrets management for developers, such as streamlined workflow, enhanced security, and reduced risk of human error. It emphasizes how secrets management can simplify development processes, improve security posture, and ultimately save time and resources. The aim is to illustrate how secrets management directly contributes to a developer's efficiency and the overall security of the project."
                },
                {
                    title: "Infrastructure, tools and technologies",
                    description: "This section provides an overview of the infrastructure requirements for implementing secrets management and reviews various tools and technologies available, such as HashiCorp Vault, AWS Secrets Manager, and Cyberark Conjur. It offers a comparison of features, usability, and scalability to help participants choose the right tools for their needs. The focus is on equipping participants with the knowledge to make informed decisions about the tools and technologies that best fit their organizational context."
                },
                {
                    title: "Case studies and real-world examples",
                    description: "This section presents case studies and real-world examples of secrets management implementations. These stories from various organizations demonstrate the practical application of concepts taught, highlighting both successes and challenges. Participants will gain insights into how different strategies work in real-world scenarios, providing valuable lessons that can be applied in their own DevOps environments."
                },
                {
                    title: "Hands-On Workshop: connecting your own secret manager to your project",
                    description: "In this hands-on workshop, participants will gain practical experience by integrating a secrets management tool into their own project. Guided by instructors, they will learn the step-by-step process of configuring and connecting a secret manager, such as HashiCorp Vault or Cyberark Conjur, to their existing development environment. This interactive session emphasizes real-world application, offering participants the opportunity to directly apply their learning and understand the nuances of effectively managing secrets within their projects."
                },
            ],
            "image": "secret-management-for-devops.png"
        },
        {
            "slug": "devsecops-for-the-business",
            "title": "DevOps Security for the Business",
            "subtitle": "Elevating Business Understanding: The Crucial Role of DevOps Security Knowledge for the business.",
            "duration": "1 half-day",
            "audience": ["Product owners", "Business consultants", "Scrum Masters", "Project Managers"],
            "description": "DevOps Security for Business is a training session aimed at integrating security into a company's DevOps processes. This training provides insights into how security can be incorporated into the DevOps cycle to enhance software security. Beyond technical aspects, it also focuses on the business side of DevOps Security, emphasizing understanding the benefits, impact, and implementation of security within the broader business strategy. The goal is to empower participants to foster a culture of security and effectively collaborate among development, security, and operational teams. The \"DevOps Security for Business\" training targets Product Owners, Scrum Masters, and other business-related roles. We firmly believe that the business side requires DevOps Security knowledge to minimize risks, increase efficiency, and strengthen team trust.",
            outline: [
                {
                    title: "Introduction in DevOps",
                },
                {
                    title: "Why the business should know about DevOps Security",
                },
                {
                    title: "Security Concepts and Terminologies",
                },
                {
                    title: "Security Challenges in DevOps",
                },
                {
                    title: "Compliance and Legislation",
                },
                {
                    title: "Security within Development",
                },
                {
                    title: "Security within Operations",
                },
                {
                    title: "Areas of Security within DevOps",
                },
            ],
            "image": "devsecops-for-the-business.png"
        },
    ],
};
