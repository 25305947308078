<template>
  <div v-if="trainingProgram" class="position-relative">
    <div
      class="position-absolute w-100 training-program-header"
      :style="{'background-image': `url(${require(`../assets/img/training/${trainingProgram.image}`)})`}"
    >
      <transition name="from-right">
        <div class="training-program-body p-3">
          <div class="d-flex justify-content-between">
            <div>
              <h1 class="mb-0">
                {{ trainingProgram.title }}
              </h1>
              <h4 class="mb-4">{{trainingProgram.subtitle}}</h4>
            </div>
            <img
              src="../assets/img/logo-v3.svg"
              class="training-program-logo"
              :alt="trainingProgram.title"
            />
          </div>

          <div>
            {{ trainingProgram.description }}
          </div>
          <hr />
          <div v-if="trainingProgram.outline">
            <h5>{{ $t('phrase.how_long') }}</h5>
            <div>
              {{ trainingProgram.duration }}
            </div>
          </div>
          <hr />
          <div v-if="trainingProgram.outline">
            <h5>{{ $t('phrase.for_who') }}</h5>
            <div
              class="badge badge-info mr-2"
              v-for="(audience_single) in trainingProgram.audience"
              :key="audience_single"
            >
              {{ audience_single }}
            </div>
          </div>
          <hr />
          <div v-if="trainingProgram.outline">
            <h5>{{ $t('word.training_outline') }}</h5>
            <ul>
              <li
                v-for="(section, index) in trainingProgram.outline"
                :key="index"
              >
                {{section.title}}
              </li>
            </ul>
          </div>

          <hr />

          <div class="text-center">
            {{ $t('phrase.interested') }}
            <a :href="getMailToString(trainingProgram)">
              {{ $t('sentence.contact_us') }}
            </a>
          </div>
        </div>
      </transition>
    </div>
    <div class="position-absolute text-white p-3" style="z-index: 1000">
      <router-link class="text-white" :to="{ name: 'TrainingList' }">
        <i class="icofont-arrow-left"></i>
        {{ $t('phrase.back_to_overview') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import trainingPrograms from "@/data/trainingPrograms";
import i18n from "@/i18n";

export default {
  name: "TrainingDetail",
  data: () => ({
    trainingPrograms: trainingPrograms,
    trainingProgram: null,
    showContent: false,
  }),
  methods: {
    getMailToString(program) {
      return `mailto:info@cisolutions.nl?subject=Interesse in training '${program.title}'`;
    },
  },
  created() {
    trainingPrograms[i18n.global.locale].forEach((program) => {
      if (program.slug === this.$route.params.slug) {
        this.trainingProgram = program;
      }
    });
    if (!this.trainingProgram) {
      this.$router.push({name: 'TrainingList'});
    }
  }
}
</script>

<style>
.training-program-header {
  height: 500px;
  background-size: cover;
  background-position: center;
  padding: 300px 100px 0 100px;
  z-index: 100;
}

.training-program-logo {
  width: 100px;
  height: 100px;
}

.training-program-body {
  border-radius: 10px;
  min-height: 600px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: rgba(165, 172, 180, 0.6) 0 8px 24px;
  border: 0;
  transition: transform .2s; /* Animation */
}

@media (max-width: 1024px) {
  .training-program-header {
    padding: 200px 10px 0 10px;
    height: 300px;
  }
}
</style>
