<template>
  <div
    class="lang-container d-flex justify-content-end"
    :class="{'lang-container-wide': adjustContainerWidth}"
  >
    <transition name="from-right">
      <div v-if="showOptions" class="d-flex align-items-center">
        <transition name="fade">
          <a
            class="lang-item clickable d-flex align-items-center"
            @click="setLanguage('en')"
            v-if="$i18n.locale === 'en' || showOptions"
          >
            <img
              class="img img-round mr-2"
              src="../assets/img/flag-uk.png"
              alt="Flag UK"
            />
            <span
              class="d-none d-lg-block"
              v-if="showOptions"
            >English</span>
          </a>
        </transition>
        <a
          class="lang-item clickable d-flex align-items-center"
          @click="setLanguage('nl')"
          v-if="$i18n.locale === 'nl' || showOptions"
        >
          <img
            class="img img-round mr-2"
            src="../assets/img/flag-nl.png"
            alt="Flag NL"
          />
          <span
            class="d-none d-lg-block"
            v-if="showOptions"
          >Nederlands</span>
        </a>
      </div>
    </transition>

    <transition name="from-right">
      <a
        class="lang-item clickable d-flex align-items-center mr-3"
        @click="setShowOptionsTrue()"
        v-if="!showOptions && !changingContainerWidth"
      >
        <img
          v-if="$i18n.locale === 'nl'"
          class="img img-round mr-2"
          src="../assets/img/flag-nl.png"
          alt="Flag NL"
        />
        <img
          v-if="$i18n.locale === 'en'"
          class="img img-round mr-2"
          src="../assets/img/flag-uk.png"
          alt="Flag NL"
        />
        {{ language }}
      </a>
    </transition>
  </div>
</template>

<script>
export default {
  name: "LanguageSelect",
  data() {
    return {
      changingContainerWidth: false,
      adjustContainerWidth: false,
      showOptions: false,
      container: {
        width: 69,
      }
    }
  },
  methods: {
    setShowOptionsTrue() {
      this.adjustContainerWidth = true;
      this.changingContainerWidth = true;
      setTimeout(() => {
        this.changingContainerWidth = false;
        this.showOptions = true;
      }, 500);
    },
    setLanguage(lang) {
      localStorage.setItem('LANG', lang);
      this.$i18n.locale = lang;
      this.adjustContainerWidth = false;
      this.changingContainerWidth = true;
      this.showOptions = false;
      setTimeout(() => {
        this.changingContainerWidth = false;
      }, 500);
    }
  },
}
</script>

<style scoped>
.img-round {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.lang-item {
  display: block;
  position: relative;
  color: #555555;
  padding: 8px 0 12px 25px;
  transition: 0.3s all;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

.lang-container {
  transition: 1s all;
  width: 69px;
}

.lang-item:hover {
  color: #D05288;
}

.lang-container-wide {
  width: 300px;
}

@media (max-width: 991px) {
  .lang-container-wide {
    width: 150px;
  }
}
</style>
