<template>
  <div
    id="use-cases"
    class="bg-work-together"
  >
    <section class="min-vh-100 overlay-black d-flex flex-column justify-content-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 d-flex flex-column justify-content-center mb-3 mb-lg-0">
            <h1 class="text-white font-weight-light text-center text-lg-left">
              {{ $t('word.use_cases') }}
            </h1>
            <div class="text-center text-lg-left">
              <span class="font-weight-light text-white">
                {{ $t('sentence.curious_what_we_can_do') }}
                <a href="#contact" v-smooth-scroll>{{ $t('word.contact') }}</a>
                {{ $t('sentence._us_for_more_info') }}.
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <v-carousel-use-cases />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CarouselUseCases from '@/components/CarouselUseCases.vue';

export default {
  name: 'Contact',
  components: {
    'v-carousel-use-cases': CarouselUseCases,
  },
};
</script>

<style>
.bg-work-together {
  background-image: url('../assets/img/work-together.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.overlay-black {
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
}
</style>
