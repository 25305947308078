<template>
  <div
    id="app"
    class="app"
    :class="{'bg-cis': !$route.meta.hideBackground}"
    ref="app"
  >
    <v-menu
      :scrollPos="scrollPos"
      v-if="$route.name === 'Home'"
    />
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Menu from './components/Menu.vue';

export default defineComponent({
  components: {
    'v-menu': Menu,
  },
  data() {
    return {
      scrollPos: 0,
    };
  },
  methods: {
    handleScroll(leftPos) {
      this.scrollPos = window.scrollY;
      this.updateBackground(leftPos);
    },
    updateBackground(leftPos) {
      this.$refs.app.style.backgroundPosition = `${leftPos}px ${(this.scrollPos * - 0.5) - 50}px`;
    }
  },
  created() {
    window.addEventListener('scroll', () => {
      if (window.innerWidth <= 768) {
        this.handleScroll(-650);
      } else {
        this.handleScroll(-500);
      }
    });
  },
});
</script>

<style>
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import './assets/lib/icofont/icofont.min.css';
@import './assets/lib/remixicon/remixicon.css';
@import './assets/lib/boxicons/css/boxicons.min.css';
@import './assets/css/style.css';
@import './assets/css/cisolutions.css';

.app {
  min-height: 100vh;
}

.bg-cis {
  background-image: url( './assets/img/logo-v3.svg');
  background-attachment: fixed;
  background-position: -500px -50px;
  background-repeat: no-repeat;
  background-size: 1300px;
}

@media (max-width: 1200px) {
  .bg-cis {
    background-size: 1100px;
  }
}

@media (max-width: 1024px) {
  .bg-cis {
    background-size: 1100px;
  }
}

@media (max-width: 991px) {
  .bg-cis {
    background-size: 1080px;
  }
}

@media (max-width: 768px) {
  .bg-cis {
    background-position: -650px -50px;
    background-size: 700px;
  }
}
</style>
