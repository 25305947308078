<template>
  <div
    id="references"
    class="bg-white"
  >
    <section
      class="min-vh-100 d-flex justify-content-center align-items-center flex-column"
    >
      <h3>
        {{ $t('sentence.who_we_work_together_with') }}
      </h3>
      <div>
        Commercialreview
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SectionReferences',
  components: {
  },
};
</script>

<style>
.bg-work-together {
  background-image: url('../assets/img/work-together.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.overlay-black {
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
}
</style>
