import { createApp, h } from 'vue';
import VueSmoothScroll from 'vue3-smooth-scroll'

import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';


import App from './App.vue'
import router from './router';
const app = createApp({
  created() {
    AOS.init()
  },
  render: () => h(App),
});

app.use(router);
app.use(VueSmoothScroll);
app.use(i18n);
app.mount('#app');
