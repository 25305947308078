<template>
  <div
    id="who-we-are"
    class="bg-white container"
  >
    <section
      class="min-vh-100 d-flex justify-content-center align-items-center flex-column"
    >
      <div class="row flex-column-reverse flex-md-row w-100 mb-5">
        <div class="col-md-6 text-justify">
          <h4 class="m-0">
            Pieter Meinema
          </h4>
          <div class="text-muted mb-2">Software/DevOps consultant</div>
          <p class="font-weight-light">
            "{{ $t('personal.pieter') }}"
          </p>
        </div>
        <div class="col-md-6 d-flex justify-content-center align-items-center mb-3 mb-md-0">
          <div class="img-us img-pieter"></div>
        </div>
      </div>

      <div class="row w-100">
        <div class="col-md-6 d-flex justify-content-center align-items-center mb-3 mb-md-0">
          <div class="img-us img-leendert"></div>
        </div>
        <div class="col-md-6 text-justify">
          <h4 class="m-0">
            Leendert Visser
          </h4>
          <div class="text-muted mb-2">Security/DevOps consultant</div>
          <p class="font-weight-light">
            "{{ $t('personal.leendert') }}"
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SectionWhoWeAre',
  components: {},
};
</script>

<style>
.img-us {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.img-pieter {
  background-image: url('../assets/img/pieter.jpeg');
}

.img-leendert {
  background-image: url('../assets/img/leendert.jpeg');
}
</style>
